/* Base
   @mixin =================================== */
ul.products li.product .woocommerce-loop-product__title a:hover {
  color: #26cc74;
}

.section-title h2:before, .section-title h2:after {
  background: #26cc74;
}

.section-title h2 span {
  color: #26cc74;
}

/* Button */
.tim-btn.tim-btn-bgt:hover {
  color: #26cc74;
}

.video-btn-two i {
  color: #26cc74;
}

.video-btn-two:hover {
  background: #26cc74;
  border-color: #26cc74;
  color: #fff;
}

.store-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

/* Header
   @mixin =================================== */
.header .top-header {
  background: rgba(38, 204, 116, 0.65);
}

.header.header-two .top-header .skew-bg {
  background: #26cc74;
}

.header.header-two .top-header .skew-bg:before {
  border-color: transparent transparent transparent #26cc74;
}

.header.header-two .top-header .user-login > li:after {
  background: rgba(0, 0, 0, 0.3);
}

.header.header-two .top-header .user-login li > a {
  color: #363636;
}

.header.header-two .top-header .user-login li > a i {
  color: #363636;
}

.header.header-two .top-header .user-login li > a:hover {
  color: #26cc74;
}

.header.header-two .header-inner {
  background: rgba(38, 204, 116, 0.2);
}

/* Top Header Right With Mini Cart */
.user-login {
  list-style: none;
}

.user-login li.cart-count .cart-overview .cart-item .product-thumbnail img {
  max-width: 100%;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-title:hover {
  color: #26cc74;
}

.user-login li.cart-count .view-cart:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.user-login li.cart-count .checkout:hover {
  background: #1ea15c;
}

.header-inner {
  background: rgba(38, 204, 116, 0.5);
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li a:hover {
  color: #26cc74;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn:hover {
  border-color: #26cc74;
}

#main-header-menu {
  list-style: none;
}

#main-header-menu > li.menu-item-has-children .sub-menu {
  border-color: #26cc74;
}

#main-header-menu > li.menu-item-has-children .sub-menu li a:hover, #main-header-menu > li.menu-item-has-children .sub-menu li a.current_page {
  color: #26cc74;
}

.header-inner .nav .head-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li::after,
.headroom--pinned.hide-topbar .header-inner #main-header-menu li::before {
  background: #26cc74;
}

/* Mobile Nav */
#nav-toggle .toggle-inner > span:nth-child(2), #nav-toggle .toggle-inner > span:nth-child(3) {
  width: 24px;
  margin-left: 6px;
  background-color: #26cc74 !important;
}

.accordion-menu ul {
  list-style: none;
}

.accordion-menu .accordion-menu li.open .dropdownlink {
  color: #26cc74;
}

.accordion-menu .dropdownlink {
  cursor: pointer;
  color: #fff;
  position: relative;
  transition: all 0.4s ease-out;
}

.accordion-menu .dropdownlink i {
  position: absolute;
  top: 13px;
  left: 16px;
  font-size: 14px;
}

.accordion-menu .dropdownlink .fa-angle-down {
  right: 12px;
  left: auto;
}

.accordion-menu .dropdownlink:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems:hover, .accordion-menu .submenuItems:focus {
  color: #26cc74;
}

.headroom--pinned.hide-topbar .header-inner {
  background: #FFF;
}

/* Banner
   @mixin =================================== */
.tim-slide-btn:hover {
  background: rgba(38, 204, 116, 0.73);
}

.banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
  color: #26cc74;
}

.banner-slider-two .swiper-slide .slider-content-two .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  color: #26cc74;
}

.header_player .jp-audio .jp-volume-bar .jp-volume-bar-value {
  background: #26cc74;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #26cc74;
}

/* Tranding Album
   @mixin =================================== */
.album-box .album-details .icon i {
  color: #26cc74;
}

.album-box .album-details .content .album-name:before, .album-box .album-details .content .album-name:after {
  background: #26cc74;
}

.album-box:hover .album-details {
  background: rgba(38, 204, 116, 0.8);
}

.album-box.album-box-two .album-details .content h3 a:hover {
  color: #26cc74;
}

.album-box.album-box-two:hover .content .album-name:before, .album-box.album-box-two:hover .content .album-name:after {
  background: #26cc74 !important;
}

/* Event About
   @mixin =================================== */
.event-content h2 span {
  color: #26cc74;
}

.event-content .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.exclusive-album-items .album-item .album-image .exse-album-name .icon {
  background: #26cc74;
}

/* Event Schedule
   @mixin =================================== */
.schedule .schedule-item .band-name {
  color: #26cc74;
}

.schedule .schedule-item .band-name:before {
  background: #26cc74;
}

/* Recent Performance
   @mixin =================================== */
.performace:before {
  background: rgba(38, 204, 116, 0.5);
}

.performace:hover .content h3, .performace:hover .content h5 {
  color: #26cc74;
}

/* Pricing
   @mixin =================================== */
.pricing-table .price {
  color: #26cc74;
}

.pricing-table .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.pricing-table:hover .pricbg path {
  fill: #26cc74;
}

/* Tabs
   @mixin =================================== */
.tabs-filter-button li a:hover {
  color: #26cc74;
}

.tabs-filter-button li.current a {
  color: #26cc74;
}

.songs-details li a:hover {
  color: #26cc74;
}

.songs-details li a span {
  color: #26cc74;
}

.songs-details li span {
  color: #26cc74;
}

/* Album
	@mixin =================================== */
.album-filter-button li:hover, .album-filter-button li.current {
  background: #26cc74;
  border-color: #26cc74;
}

.album-filter-button li:hover a, .album-filter-button li.current a {
  color: #fff;
}

.album-filter-button.album-filter-button-two li:hover, .album-filter-button.album-filter-button-two li.current {
  border-color: #26cc74;
  border: 1px dashed #26cc74;
}

.album-filter-button.album-filter-button-two li:hover a, .album-filter-button.album-filter-button-two li.current a {
  color: #26cc74;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.tim-isotope.tim-isotope-2 .tim-album-item:before {
  background: rgba(38, 204, 116, 0.58);
}

/* Exclusive Album */
.exclusive-album-items {
  padding: 0 5px;
}

.exclusive-album-items .album-item:before {
  background: rgba(38, 204, 116, 0.5);
}

.exclusive-album-items .album-item .album-image img {
  max-width: 100%;
}

.exclusive-album-items .album-item .album-image .exse-album-name h4 {
  background: #26cc74;
}

.exclusive-album-items .album-item .content .icon a:hover {
  color: #26cc74;
}

/* Feature Album
   @mixin =================================== */
.feature-albums .tim-album-item .album_details_wrap .tim-btn:hover {
  color: #fff;
  background: #26cc74;
  border-color: #26cc74;
}

/* About
	@mixin =================================== */
.artist-music-inner .aritist-music .icon i {
  color: #26cc74;
}

.album-cover-img img {
  max-width: 100%;
  margin-bottom: 30px;
}

.artist-social-link li a:hover {
  border-color: #26cc74;
  color: #26cc74;
}

/* Music Playlist One */
.hit-plalist li .video-song:hover, .hit-plalist li .song-download:hover {
  color: #26cc74;
}

.hit-plalist li.active {
  background: #26cc74;
}

.hit-plalist li.active .video-song:hover, .hit-plalist li.active .song-download:hover {
  color: #0e4b2b;
}

.album-feature .artist-music-inner {
  background: rgba(38, 204, 116, 0.8);
}

/* Newsletter
	@mixin =================================== */
.newsletter-inner .newsletter-form .tim-btn:hover {
  background: #22b768;
}

/* Newsletter Two */
.newsletter-wrap-two:before {
  background: rgba(38, 204, 116, 0.5);
}

/* Sale Album
	@mixin =================================== */
.slider-content ul li:before {
  color: #26cc74;
}

.sale-tabs li a.active:after {
  background: #26cc74;
}

/* Upcomiong Concerts
	@mixin =================================== */
.concerts .countdown {
  background: #26cc74;
}

.concerts .ticket .tic-btn {
  color: #26cc74;
}

.concerts .ticket .tic-btn.tic-btn-bg {
  background: #26cc74;
}

/* Upcomming Concert Three */
.upc-con-three .content-wrap .content h3 {
  color: #26cc74;
}

.upc-con-three .upc-count-wrap .tic-btn:hover {
  background: #26cc74;
  color: #fff;
  border-color: #26cc74;
}

/* Ticket
	@mixin =================================== */
.live-ticket h2 span {
  color: #26cc74;
}

.live-ticket h4 span {
  color: #26cc74;
}

.live-ticket .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.artist-details-two .band-name {
  color: #26cc74;
}

/* Widgets
	@mixin =================================== */
.widget.widget-shop .widget-title-shop span {
  color: #26cc74;
}

.widget .widget-title span {
  color: #26cc74;
}

.widget ul li a:hover {
  color: #26cc74;
}

/* Widget Newsletter */
.widgit_newsletter .newsletter-form .tim-btn:hover {
  background: #26cc74;
}

/* Popular Post */
.widgets-popular-post li .content .entry-meta.cat a {
  color: #26cc74;
}

.ui-slider-horizontal .ui-slider-range {
  background-color: #26cc74;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  background: #26cc74;
}

.ui-slider .ui-slider-handle {
  background: #26cc74;
}

.fil-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

/* Best Sales */
.best-sales .sales-item:before {
  background: rgba(38, 204, 116, 0.9);
}

.best-sales .sales-item .content:hover {
  color: #26cc74;
}

/* Lesson
   @mixin =================================== */
.lesson-box .content h3 a:hover {
  color: #26cc74;
}

/* Ultimate Tabs
   @mixin =================================== */
.music-tab > a span {
  color: #26cc74;
}

.music-tab .content h3 a:hover {
  color: #26cc74;
}

.music-tab .content .ratting li {
  color: #26cc74;
}

.music-tab.music-tab-two .content h3 a:hover {
  color: #26cc74;
}

.music-tab.music-tab-two .view-btn {
  position: relative;
  color: #444;
  right: auto;
}

.music-tab.music-tab-two .view-btn:hover {
  color: #26cc74;
}

/* Intro Video
   @mixin =================================== */
.video-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.watch-inner .tim-btn:hover {
  background: #26cc74;
  border-color: #26cc74;
}

.watch-inner .tim-btn.tim-btn-bg {
  background: #26cc74;
  border-color: #26cc74;
}

/* Blog
   @mixin =================================== */
.blog-post-grid .entry-date {
  background: #26cc74;
}

.blog-post-grid .entry-content {
  padding: 30px 25px;
}

.blog-post-grid .entry-content .entry-header .entry-title a:hover {
  color: #26cc74;
}

.blog-post-grid .entry-content .read-btn:hover {
  color: #26cc74;
}

.blog-post-grid .entry-meta a:hover {
  color: #26cc74;
}

.blog-post-grid .post-type-icon {
  background: #26cc74;
}

/* Blog List */
.blog-post .tim-btn-bgt.read-more-btn:hover {
  border-color: #26cc74;
  background: #26cc74;
}

.entry-content-wrapper .entry-meta ul.meta li a:hover {
  color: #26cc74;
}

.entry-content-wrapper .entry-meta ul.meta li .author a {
  color: #26cc74;
}

.entry-content-wrapper .entry-header .entry-title a:hover {
  color: #26cc74;
}

.entry-content-wrapper .blog-footer .tag a:hover {
  background: #26cc74;
}

.post-on:before {
  background: rgba(38, 204, 116, 0.9);
}

ul.shared-link li:hover:before {
  background: #26cc74;
  border-color: #26cc74;
}

.blog-count-details a i {
  margin-right: 5px;
  color: #26cc74;
}

.post-type-icon:before {
  background: #26cc74;
}

/* Comment */
ul.comment-list {
  padding: 60px 40px;
}

ul.comment-list .comment-body .reply a:hover {
  color: #26cc74;
}

.comment-form:after {
  content: '';
  clear: both;
  display: block;
}

.comment-form p.comment-form-comment textarea:hover, .comment-form p.comment-form-comment textarea:focus {
  border-color: #26cc74;
}

.comment-form p input:hover, .comment-form p input:focus {
  border-color: #26cc74;
}

.comment-form .form-submit .submit:hover {
  background: #26cc74;
  color: #fff;
  border-color: #26cc74;
}

ul.comment-list .comment-body .comment-author a {
  color: #26cc74;
}

/* Post Navigation */
.posts-navigation ul li:hover a, .posts-navigation ul li.active a {
  color: #26cc74;
}

/* Woocommerce
   @mixin =================================== */
ul.products li.product .product-thumb .new {
  background: #26cc74;
}

ul.products li.product .product-thumb:before {
  background: rgba(38, 204, 116, 0.7);
}

ul.products li.product .woocommerce-loop-product__title:hover {
  color: #26cc74;
}

ul.products li.product .price .woocommerce-Price-amount {
  color: #26cc74;
}

ul.products li.product-two .product-thumb .product-details a:hover i {
  color: #26cc74;
}

ul.products li.product-two .product-thumb span:before {
  font-size: 14px;
  color: #26cc74;
}

/* Product 3 Column */
.site-main .columns-3 ul.products li.product {
  width: 33.33%;
  padding: 0 15px;
  margin: 0 0 40px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button:hover {
  background: #26cc74;
  border-color: #26cc74;
  color: #fff;
}

.site-main .tim-cart-btn {
  background: #26cc74;
}

.product_meta .posted_in a:hover {
  color: #26cc74;
}

.product-share-link li a:hover {
  background: #26cc74;
}

/* Shop Page */
.single-product-details .star-rating span:before {
  color: #26cc74;
}

/* Product Single */
.product-tab-wrapper .product-tabs li a:hover, .product-tab-wrapper .product-tabs li a.active {
  color: #26cc74;
  border-color: #26cc74;
}

.product-tab-wrapper .tab-content .tab-pane {
  padding: 0;
}

.review-list .review-comment .comment-content .star-rating span:before {
  color: #26cc74;
}

.review-form .gp-btn:hover {
  background: #26cc74;
}

/* Rating Star Widgets Style */
.rating:hover .star,
.rating.vote-cast .star {
  color: #26cc74;
}

/* Product Size */
.options__item span:hover, .options__item span.active {
  background: #26cc74;
  border-color: #26cc74;
}

.woocommerce .wc-proceed-to-checkout .gp-btn {
  background: #26cc74;
}

.product-cart .tim-cart-btn {
  background: #26cc74;
}

/* Footer
   @mixin =================================== */
/* Footer Latest Post */
.footer-blog-inner .footer-blog .content h3 a:hover {
  color: #26cc74;
}

.footer-blog-inner .footer-blog .content .meta:hover {
  color: #26cc74;
}

/* Tags */
.tagcloud a:hover {
  background: #26cc74;
}

/* Widget Newsletter */
.footer-newsletter #widget-newsletter .submit {
  background: #26cc74;
}

.footer-newsletter #widget-newsletter .submit:hover {
  background: #444;
}

/*  Instagram Widget */
.instagram-feed a {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
}

.instagram-feed a img {
  max-width: 100%;
}

/* Footer Social Link */
.footer-social-link {
  height: 60px;
}

.footer-social-link li:before {
  background: #26cc74;
}

.copyright-text p a {
  color: #26cc74;
}

.mag-header-inner .mag-bn::before, .mags-post-content > a, .mheader-top span, .single-concert > a, .mag-hot-post .mhp-icon > a {
  background: #26cc74;
}

.mag-header-inner h2 span, .hp-content a, .mags-post-content p a, .mags-post-content p a, .mag-show-content > a, .mag-post-content span i, .magazine-post-small-video .mag-post-content-small span i, .single-concert .sin-concert-content span a, .concer-sin-content span, .magazine-next-cons-content > a, .mag-show-content span i, .magazine-next-cons-content span i, .mag-hot-post .mhp-footer i, .mag-twitter-widget .single-twitter .twt-top span, .mag-post-content-small h2 a:hover, .mag-post-content-small span i, .headroom--pinned.hide-topbar .header-inner #main-header-menu li.active > a {
  color: #26cc74;
}

.magazine-tab-btn .nav-item a:hover, .magazine-tab-btn .nav-item a.active, .mag-post-content h2 a:hover, .mag-show-content h2 a:hover, .magazine-post-small-video .mag-post-content-small h2 a:hover, .single-concert .sin-concert-content h4 a:hover, .magazine-next-cons-content h2 a:hover, .mag-hot-post h2 a:hover, .concer-sin-content h2 a:hover {
  color: #26cc74;
}

.magazine-artist:hover .magazine-artist-info {
  background: rgba(38, 204, 116, 0.4);
}

.magazine-artist::before {
  background: rgba(38, 204, 116, 0.4);
}

.mag-tag-widget ul li:hover::before {
  background: #26cc74;
}
